import React from 'react'
import { Sticky } from 'src/uikit'
import {
  Wrap,
  WrapItem
} from '@chakra-ui/react'

import {
  FiltersModal,
  SortsModal,
} from '.'

const MobileActionBar = () => {
  return (
    <Sticky h="14" visible={true}>
      <Wrap spacing="0">
        <WrapItem w="50%">
          <SortsModal />
        </WrapItem>
        <WrapItem w="50%">
          <FiltersModal />
        </WrapItem>
      </Wrap>
    </Sticky>
  )
}

export default MobileActionBar
