import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import {
  CalendarIcon,
  CarIcon,
} from 'src/icons'
import { theme } from 'src/themes'
import {
  BadgeWithIcon,
  InfoBadge,
  LimitedOfferBadge,
  OfferCardTitle,
  PriceBox,
} from 'src/uikit'
import {
  Box,
  Divider,
  Stack,
  Text,
} from '@chakra-ui/react'

interface Props {
  item: OfferGlobal.RentOfferItem
}

const Offer: FC<Props> = ({ item }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const title = `${item.brand} ${item.model}`
  const altTitle = `Noleggio a Lungo Termine ${item.brand} ${item.model}`
  const subtitle = item.version
  const imageSrc = item.image
  const href = [
    '/noleggio-a-lungo-termine/',
    item.brandCode,
    '/',
    item.modelCode,
    '/',
    item.code,
    '?deposit=',
    item.deposit,
    '&contractLength=',
    item.contractLength,
    '&annualMileage=',
    item.annualMileage
  ].join('')
  const durationTitle = t('nltOffersComponent:durationTitle')
  const durationValue = t('nltOffersComponent:durationValue', { value: item.contractLength })
  const distanceTitle = t('nltOffersComponent:distanceTitle')
  const distanceValue = t('nltOffersComponent:distanceValue', {
    value: item.annualMileage.toLocaleString('it-IT'),
  })
  const depositTitle = t('nltOffersComponent:depositTitle')
  const depositValue = item.deposit === 0
    ? t('nltOffersComponent:depositValue0', {
      value: item.deposit,
    }) : t('nltOffersComponent:depositValue', {
      value: item.deposit.toLocaleString('it-IT'),
    })
  const priceDescription = t('nltOffersComponent:priceDescription')
  const priceValue = t('nltOffersComponent:priceValue', {
    value: item.monthlyRate.toLocaleString('it-IT'),
  })

  let limitedOfferBadge = null

  if (item.expirationDate) {
    const offerTimeStamp = Date.parse(item.expirationDate)
    const nowTimeStamp = (new Date()).getTime()
    const diffTimeStamp = offerTimeStamp - nowTimeStamp

    if (diffTimeStamp > 0) {
      limitedOfferBadge = t('nltOffersComponent:limitedOfferBadge', {
        value: Math.floor((diffTimeStamp) / (24 * 60 * 60 * 1000))
      })
    }
  }

  return (
    <Box
      title={altTitle}
      borderRadius={10}
      bg="baseWhite"
      pb="l"
      overflow="hidden"
      pos="relative"
      borderColor={theme.colors.borders.grey}
      borderWidth={1}
      boxShadow={theme.shadows.cards}
      _hover={{
        cursor: 'pointer',
        textDecoration: 'none',
        boxShadow: '0 4px 12px 0px rgba(65, 164, 118, 0.22)',
      }}
      onClick={() => router.push(href)}
    >
      <Box h="12.5rem" mb="xs" pos="relative">
        <Image src={imageSrc} alt={altTitle} layout="fill" objectFit="contain" loading="lazy" />
        {limitedOfferBadge &&
          <LimitedOfferBadge position="absolute" top="1rem" left="1rem">
            {limitedOfferBadge}
          </LimitedOfferBadge>
        }
        {item.mainTag &&
          <InfoBadge position="absolute" bottom=".625rem" left="1rem">
            {item.mainTag}
          </InfoBadge>
        }
        <Divider position="absolute" bottom="0rem" />
      </Box>
      <Stack textAlign="left" h="8.5rem" pos="relative" px="m" position="static">
        <a href={href} style={{ color: theme.colors.baseDark }} title={altTitle}>
          <OfferCardTitle title={title} subtitle={subtitle} />
        </a>
        <Box marginLeft="l" position="static">
          <PriceBox
            value={priceValue}
            description={priceDescription}
            textAlign="right"
            position="absolute"
            top="16.2rem"
            right="1rem"
          />
          <BadgeWithIcon icon={<CarIcon />} name={distanceTitle} value={distanceValue} />
          <BadgeWithIcon icon={<CalendarIcon />} name={durationTitle} value={durationValue} />
          <BadgeWithIcon icon={<CarIcon />} name={depositTitle} value={depositValue} />
          <Text
            color="baseDark"
            fontFamily="Montserrat-SemiBold"
            fontSize="0.75rem"
            letterSpacing="0.4px"
            marginTop="xs"
            h="16px"
          >
            {item.variations > 0 ? t('nltOffersComponent:variations', {
              count: item.variations,
            }) : null}
          </Text>
        </Box>
      </Stack>
    </Box>
  )
}

export default Offer
