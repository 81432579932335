import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FiltersIcon } from 'src/icons'
import {
  Button,
  Center,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { resetAllFilters } from 'src/redux/nlt-offer/actions'
import { FiltersList } from '.'

const FiltersModal = () => {
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()
  const offers = useSelector((state: Store.RootState) => state.nlt.filteredOffers)
  const selectedFilters = useSelector((state: Store.RootState) => state.nlt.selectedFilters)
  let selectedFiltersCount = 0;

  useEffect(() => {
    selectedFiltersCount = selectedFilters.reduce((prev, current) => prev + current.items.length, 0)
  }, [])

  const resetFiltersHandler = () => {
    dispatch(resetAllFilters())
    onClose();
  }

  return (
    <>
      <Center w="100%" h="14" bg="primaryGreen.300" as="button" onClick={onOpen}>
        {selectedFiltersCount > 0 ? t('nltActionbarContainer:filters', { count: selectedFiltersCount }) : t('nltActionbarContainer:showFilters')}
        <FiltersIcon />
      </Center>
      <Modal onClose={onClose} size="full" isOpen={isOpen} scrollBehavior="inside">
        <ModalContent>
          <ModalHeader textAlign="center">{t('nltMobileFiltersModalContainer:doFilter')}</ModalHeader>
          <ModalCloseButton mt="1" />
          <Divider />
          <ModalBody p="0" bg="baseNeutral.200">
            <FiltersList />
          </ModalBody>
          <ModalFooter display="block" padding="0">
            <Wrap spacing="0">
              <WrapItem w="50%">
                <Button
                  w="100%"
                  h="14"
                  borderColor="primaryGreen.300"
                  color="primaryGreen.900"
                  variant="outline"
                  borderRadius="0"
                  onClick={resetFiltersHandler}
                >
                  {t('nltMobileFiltersModalContainer:resetFilters')}
                </Button>
              </WrapItem>
              <WrapItem w="50%">
                <Center w="100%" h="14" bg="primaryGreen.300" as="button" onClick={onClose}>
                  {t('nltMobileFiltersModalContainer:showFoundOffer', { value: offers.results })}
                </Center>
              </WrapItem>
            </Wrap>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FiltersModal
