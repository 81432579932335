import React from 'react'
import { theme } from 'src/themes'
import {
  Accordion,
  Heading,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { toggleFilter } from 'src/redux/nlt-offer/actions'
import {
  FiltersListBox,
  FiltersListItem,
  FiltersListItemContent,
  FiltersListItemNoChildren,
  FilterSwitch,
} from '.'

const FiltersListGroup = ({ groupLabel, group }) => {
  const dispatch = useDispatch()
  const isMobile = useBreakpointValue({ base: true, md: false })
  const toggleFilterHandler = (value, groupKey) => dispatch(toggleFilter(value, groupKey))

  return isMobile ?
    <>
      {
        group.length === 1 ?
          <Accordion fontFamily="Montserrat-Regular" allowToggle={true} defaultIndex={[0]}>
            <FiltersListBox>
              <FiltersListItemContent filter={group[0]} />
            </FiltersListBox>
          </Accordion>
          :
          group.map(filter =>
            <Accordion fontFamily="Montserrat-Regular" allowToggle={true} defaultIndex={[0]} key={filter.label}>
              <FiltersListBox>
                <FiltersListItemContent filter={filter} />
              </FiltersListBox>
            </Accordion>
          )
      }
    </>
    :
    <FiltersListBox>
      {
        group.length === 1 ?
          <>
            {group[0].type === 'switch'
              ?
              <FilterSwitch
                groupKey={group[0].key}
                title={group[0].group}
                label={group[0].label}
                onChange={({ target }) => toggleFilterHandler(target.value, group[0].key)}
              />
              :
              <>
                <Heading
                  as="h4"
                  fontFamily="Montserrat-SemiBold"
                  fontSize={theme.space.m}
                  color="baseNeutral.700"
                  pb={4}
                >
                  {groupLabel}
                </Heading>
                <FiltersListItemNoChildren items={group[0].items} groupKey={group[0].key} />
              </>
            }
          </>
          :
          <>
            <Heading
              as="h4"
              fontFamily="Montserrat-SemiBold"
              fontSize={theme.space.m}
              color="baseNeutral.700"
              pb={4}
            >
              {groupLabel}
            </Heading>
            <FiltersListItem items={group} />
          </>
      }
    </FiltersListBox>
}

export default FiltersListGroup
