import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { theme } from 'src/themes'
import {
  Button,
  GridItem,
  Text,
} from '@chakra-ui/react'
import { toggleFilter, resetAllFilters } from 'src/redux/nlt-offer/actions'
import { filters, selectedFilters as selectedFiltersSelector, getFilterByGroupAndValue } from 'src/redux/nlt-offer/selectors'
import { FilterTag } from '.'

const SuggestedFilters = ({ colSpan }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const allFilters = useSelector(filters)
  const selectedFilters = useSelector(selectedFiltersSelector)
  const suggestedFilters = useSelector((state: Store.RootState) => state.nlt.suggestedFilters)
  const toggleFilterHandler = (filter, groupKey) => dispatch(toggleFilter(filter.value, groupKey))
  const resetFiltersHandler = () => dispatch(resetAllFilters())

  const [isMounted, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  let selectedFiltersExceptSuggested: Array<Filter.SuggestedItem> = [];

  selectedFilters.forEach((selectedFilter: any) => {
    suggestedFilters.forEach((suggestedFilter: any) => {
      let items: Array<Filter.Tag | Filter.TagImage> = []
      let selectedFiltersExceptSuggestedItem = { key: selectedFilter.key, items };

      if (selectedFilter.key === suggestedFilter.key) {
        selectedFilter.items.forEach((selectedItem: any) => {
          if (!suggestedFilter.items.length || !suggestedFilter.items.some(suggestedItem => suggestedItem.name === selectedItem.name)) {
            const selectedCompleteItem = getFilterByGroupAndValue(allFilters, selectedFilter.key, selectedItem.value)

            if (selectedCompleteItem) {
              selectedFiltersExceptSuggestedItem.items.push(selectedCompleteItem);
            }
          }
        })
      } else {

        selectedFiltersExceptSuggestedItem.items = selectedFilter.items.map(filterItem => {
          const validatedFilterItem = getFilterByGroupAndValue(allFilters, selectedFilter.key, filterItem.value)

          if (validatedFilterItem) {
            return {
              ...validatedFilterItem
            }
          }
          return null;
        }).filter(filterItem => filterItem !== null)

      }

      selectedFiltersExceptSuggested.push(selectedFiltersExceptSuggestedItem)
    })
  })

  const displayResetFilters = selectedFilters.flatMap(selectedFilter => selectedFilter.items).length > 0

  return (
    <GridItem
      display={{ base: 'none', sm: 'block' }}
      colSpan={colSpan}
      mt="-2" textAlign="left" key="filtri-suggeriti">
      <Text fontFamily="Montserrat-SemiBold" fontWeight="bold" fontSize={theme.space.s} color="baseNeutral.700">
        {t('offersListContainer:suggestedFilters')}
      </Text>
      {suggestedFilters.map((filterGroup: any) =>
        filterGroup.items.map((item: Filter.Tag) =>
          <FilterTag
            item={item}
            groupKey={filterGroup.key}
            key={`suggested-${item.name}-${item.value}`}
            onClick={() => toggleFilterHandler(item, filterGroup.key)}
          />
        )
      )}
      {selectedFiltersExceptSuggested.map((filterGroup: any) =>
        filterGroup.items.map((item: Filter.Tag) =>
          <FilterTag
            item={item}
            groupKey={filterGroup.key}
            key={`filtered-${item.name}-${item.value}`}
            onClick={() => toggleFilterHandler(item, filterGroup.key)}
            hasCloseButton={true}
          />
        )
      )}
      {displayResetFilters && <Button
        onClick={resetFiltersHandler}
        color="baseDark"
        variant="link"
        fontFamily="Montserrat-Medium"
        fontWeight="normal"
        ml="m"
      >
        {t('offersListContainer:resetFilters')}
      </Button>}
    </GridItem>
  )
}

export default SuggestedFilters
